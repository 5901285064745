import React from 'react'

function Error() {
  return (
    <div>
   <div class="alert alert-danger" role="alert">
     <h5><b>Invalid Try Again!</b> </h5> 
  </div>
  </div>
  )
}

export default Error